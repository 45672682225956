import React, { useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  @media (max-width: 580px) {
    display: none;
  }

  .cursor {
    position: absolute;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    z-index: 1;
    transition: transform 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33),
      opacity 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33),
      background-color 0.2s ease-in;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    transform: scale(1);

    &.active {
      opacity: 0.6;
      transform: scale(2);
    }

    &.black {
      background-color: #555;
      transition: background-color 0.2s ease-in;
    }

    &.hovered {
      opacity: 0.08;
    }
  }

  .cursor-follower {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.3);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    z-index: 1;
    transition: transform 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33),
      opacity 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33),
      background-color 0.2s ease-in;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    transform: translate(5px, 5px);

    &.active {
      opacity: 0.7;
      transform: scale(3);
    }

    &.black {
      background-color: rgba(255, 255, 255, 1);
      mix-blend-mode: difference;
      transition: background-color, mix-blend-mode, transform 0.2s ease-in;
    }

    &.hovered {
      opacity: 0.08;
    }
  }
`;

const Cursor = () => {
  useEffect(() => {
    // let cursor = window.$(".cursor"),
    //   follower = window.$(".cursor-follower");

    let cursor = document.getElementsByClassName("cursor")[0],
      follower = document.getElementsByClassName("cursor-follower")[0],
      link = document.getElementsByClassName("link"),
      black = document.getElementsByClassName("black"),
      white = document.getElementsByClassName("white");

    let posX = 0,
      posY = 0;

    let mouseX = 0,
      mouseY = 0;

    window.TweenMax.to({}, 0.016, {
      repeat: -1,
      onRepeat: function() {
        let scroll = window.scrollY;
        posX += (mouseX - posX) / 9;
        posY += (scroll + mouseY - posY) / 9;

        // console.log(posY, document.innerHeight);

        if (mouseY < window.innerHeight - 12) {
          window.TweenMax.set(follower, {
            css: {
              left: posX - 12,
              top: posY - 12,
            },
          });
        }

        if (mouseY < window.innerHeight - 5) {
          window.TweenMax.set(cursor, {
            css: {
              left: mouseX,
              top: mouseY + scroll,
            },
          });
        }
      },
    });

    document.addEventListener("mousemove", (e) => {
      mouseX = e.pageX;
      mouseY = e.pageY - window.scrollY;

      Array.from(link).forEach(function(el) {
        el.addEventListener("mouseenter", () => {
          cursor.classList.add("active");
          follower.classList.add("active");
        });

        el.addEventListener("mouseleave", () => {
          cursor.classList.remove("active");
          follower.classList.remove("active");
        });
      });

      Array.from(black).forEach(function(el) {
        el.addEventListener("mouseenter", () => {
          cursor.classList.add("black");
          follower.classList.add("black");
        });

        el.addEventListener("mouseleave", () => {
          cursor.classList.remove("black");
          follower.classList.remove("black");
        });
      });

      Array.from(white).forEach(function(el) {
        el.addEventListener("mouseenter", () => {
          cursor.classList.remove("black");
          follower.classList.remove("black");
        });
      });
    });
  });

  return (
    <Wrapper>
      <div className="cursor" />
      <div className="cursor-follower" />
    </Wrapper>
  );
};

export default Cursor;
