import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./theme/globalStyle";
import Container from "./components/Container";
import Cursor from "./components/Cursor";

import registerServiceWorker from "./registerServiceWorker";

const App = () => {
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 0);
  // }, []);
  return (
    <Router>
      {/* {loading ? (
        <Loader />
      ) : ( */}
      <div>
        <Container />
        <Cursor />
      </div>
      {/* )} */}
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
