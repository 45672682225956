import { injectGlobal } from "styled-components";

injectGlobal`

@font-face {
  font-family: LeagueSpartan;
  font-weight: bold;
  src: url("./fonts/LeagueSpartan-Bold.otf") format("opentype");
  font-display: block;
}

@font-face {
  font-family: Spartan-Reg;
  src: url("./fonts/SpartanMB-Regular.otf") format("opentype");
  font-display: block;
}

:root {
    /* Colors */
    --primary-blue: #0f3242;
    --primary-pink: #fb9fa4;
}

  *,
  *::after,
  *::before {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
  }

  ::-webkit-scrollbar {
    background-color: var(--primary-blue);
    background-color: transparent;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: var(--primary-blue);
  }
  ::-webkit-scrollbar-track:hover {
    background-color: var(--primary-blue);
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-pink);
    border-radius: 16px;
    border: 5px solid var(--primary-blue);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-pink);
    border: 5px solid var(--primary-blue);
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }

  html {
    font-size: 62.5%;
    color: white;
    background: white !important;
  }

  body {
    font-family: LeagueSpartan;
    overflow-x: hidden;
    cursor: none;
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 2rem;
  }

  p {
    font-size: 1.4rem;
  }

  /* Keyframes */

  @keyframes slideRight {
    0% {
      right: -1vw;
    }
    60% {
      right: 4vw;
    }
    100% {
      right: 3vw;
      opacity: 1;
    }
  }

  @keyframes slideLeft {
    0% {
      right: 7vw;
    }
    60% {
      right: 2vw;
    }
    100% {
      right: 3vw;
      opacity: 1;
    }
  }

  @keyframes slideUp {
    0% {
      transform: translateY(10vh);
    }
    70% {
      transform: translate3d(0px, -2vh, 25px);
      opacity: 1;
    }
    100% {
      transform: translate3d(0px, 0px, 25px);
      opacity: 1;
    }
  }

  @keyframes slideUp-sm {
    0% {
      transform: translateY(10vh);
    }
    70% {
      transform: translateY(-2vh);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes menu-sm {
    0% {
      transform: translateX(-100vw);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
