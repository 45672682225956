import React, { lazy, Suspense } from "react";
import styled from "styled-components";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const Home = lazy(() => import("./Home"));
const Profile = lazy(() => import("./profile/Profile"));
const Work = lazy(() => import("./work/Work"));

const Wrapper = styled.div`
  .fade-enter {
    opacity: 0.01;
    /* transform: translateX(100vw) skewX(-55deg); */

    &::before {
      content: "";
      position: absolute;
      height: 100vh;
      width: 100vw;
      background: white;
      opacity: 1;
      transition: all 500ms 700ms ease-in;
      /* transform: translateX(100vw) skewX(-55deg); */
      /* transition: all 500ms 800ms ease-out; */
    }
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* transform: translateX(0vw) skewX(0deg); */
    transition: all 500ms 700ms ease-in;

    &::before {
      content: "";
      /* position: absolute;
      height: 100vh;
      width: 100vw; */
      /* background: white; */
      opacity: 0.01;
      z-index: 9999;
      /* transform: translateX(100vw) skewX(-55deg); */
      transition: all 1000ms 700ms ease-in;
    }
  }

  .fade-exit {
    opacity: 1;
    /* transform: scale(1); */
    z-index: 9998;
    /* position: absolute; */
    /* transform: translateX(0vw) skewX(0deg); */

    &::before {
      content: "";
      position: absolute;
      height: 100vh;
      width: 100vw;
      background: white;
      z-index: 9999;
      transform: translateX(100vw) skewX(-55deg);
      /* transition: all 800ms ease-in-out; */
    }
  }

  .fade-exit-active {
    opacity: 0.01;
    z-index: 9998;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 800ms ease-in;
    /* transform: translateX(-100vw) skewX(-35deg); */

    &::before {
      content: "";
      background: white;
      transform: translateX(0vw) skewX(0deg);
      transition: all 800ms ease-out;
    }
  }

  div.transition-group {
    position: relative;
  }
  section.route-section {
    position: absolute;
  }
`;

function Container({ location }) {
  return (
    <Wrapper>
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={location.key}
          timeout={{ enter: 2000, exit: 800 }}
          classNames={"fade"}
        >
          <section className="route-section">
            <Switch location={location}>
              <Route exact path="/" component={LoadingComponent(Home)} />
              <Route path="/profile" component={LoadingComponent(Profile)} />
              <Route path="/work" component={LoadingComponent(Work)} />
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
    </Wrapper>
  );
}

function LoadingComponent(Component) {
  return props => (
    <Suspense fallback={<span>Loading...</span>}>
      <Component {...props} />
    </Suspense>
  );
}

export default withRouter(Container);
